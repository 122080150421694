@import "@/assets/styles/_vars.scss";

	.creatures-view{
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-rows: auto;
		grid-template-areas:
			'creature-list creature-list creature-list'
			'creature-details creature-details creature-details';

		@media screen and (min-width: 725px) {
			grid-template-areas:
			'creature-list creature-details creature-details';
		}
	}
