@import "@/assets/styles/_vars.scss";

	.creature-detail-action-block{
		h3{
			color: $contrast-2;
			margin-bottom: 6px;
			border-bottom: 2px solid $contrast-2;
		}

		ul{
			li{
				padding: 10px 0;

				span ::v-deep .subactions li{
					padding: 10px;
				}
			}
		}
	}
