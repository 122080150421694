@import "@/assets/styles/_vars.scss";

	.creature-detail-scores{

		.scores{
			width: 80%;
			margin: 30px auto;
			display: flex;
			flex-direction: row;
			flex-wrap: wrap;

			.score{
				padding: 3px;
				flex-grow: 1;
			}
		}
	}
