@import "@/assets/styles/_vars.scss";

.creature-list-item{
	padding: 5px;
	border: 1px solid $contrast-4;
	&:hover{
		background-color: $contrast-light-2
	}

	a{
		display: inline-block;
		width: 100%;
		height: 100%;
		color: inherit;
		text-decoration: none;

		&:hover{
			color: inherit;
		}
	}
}
