@import "@/assets/styles/_vars.scss";

	.update-bar{
		bottom: 15px;
		right: 50px;
		position: fixed;
		z-index: 150;
		width: max-content;
		padding: 10px;

		background-color: lightgray;

		button{
			margin-top: 5px;
		}
	}
