@import "@/assets/styles/_vars.scss";

.creature-details{
	margin: 20px;
	padding: 0 15px;
	border: 5px solid $contrast-1;
	border-radius: 1%;
	grid-area: creature-details;
	background-color: $background-alt;

	div{
		border-top: 2px solid $contrast-2;

		&:first-child, &.no-border{
			border-top: none;
		}
	}

}
