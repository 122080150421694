@import "@/assets/styles/_vars.scss";

	button {
		width: 2em;
		& .icon-default {
			display: inline;
		}
		& .icon-hover {
			display: none;
		}

		&:hover {
			& .icon-default {
			display: none;
			}
			& .icon-hover {
				display: inline;
			}
		}
	}

	input {
		width: 2em;
		margin: 10px;
		text-align: center;

		&::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		&[type=number] {
			-moz-appearance: textfield;

		}
	}
