@import "@/assets/styles/_vars.scss";

	.creature-detail-special{
		text-align: left;

		.spellcasting{
			div{
				margin: 15px 0;
			}

			p{
				margin: 2px 0;
			}

			li{
				padding: 3px 0;
			}
		}
	}
