@import "@/assets/styles/_vars.scss";

.creature-list{
	grid-area: creature-list;
	margin: 5px;

	ul{
		list-style: none;
		padding: 0;

		li{
			&:nth-child(even){
				background-color: $contrast-light;
			}
		}
	}
}
