@import "@/assets/styles/_vars.scss";

	.menu-bar{
		border-top: 3px solid $contrast-1;
		height: 100px;
		width: 100%;

		position: fixed;
		bottom: 0;
		left: 0;

		display: flex;
		flex-direction: row;
		align-content: center;

		background-color: $contrast-light;

		@media screen and (min-width: 800px),
			screen and (max-height: 400px){
			flex-direction: column;
			height: 100%;
			width: 100px;
		}

		a{
			color: $contrast-4;

			&:hover{
				color: $contrast-3;
			}

			margin: auto;
			padding: 10px;
			div{

				height: fit-content;
				.icon{
					height: 60px;
				}
				span{
					display: inline-block;
					width: 100%;
				}
			}
		}

		.active-page{
			color: $contrast-2;
		}
	}
